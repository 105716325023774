import { Component, ViewChild, OnInit } from '@angular/core';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../common/services/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../../common/services/login.service';
import swal from "sweetalert2";
import { HeaderService } from '../../common/services/header.service';
import { first, timeout } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild('sealRef') sealRef;

  registerForm: FormGroup;
  contentCms: any;
  logo: string;
  imagen_login: string;
  company_prin: string;
  cmsData: any;

  public data: any;
  public dataForm = {
    inputEmail: '',
    inputPassword: '',
    inputCompany: ''
  };
  public contentB2s = {
    contact: 0,
    provider: 0
  };
  public companiaPrincipal: any;
  public thirdCompanies: any[] = [];
  public mostrarBotonRegistroProveedores: boolean;

  constructor(private _authService: AuthService,
    private _router: Router,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private headerService: HeaderService,
    private _route: ActivatedRoute,
    private _ngxService: NgxUiLoaderService,
    private _loginService: LoginService) {

    if (this._authService.isAutenticate()) {
      this._router.navigate(['/account-statements', 0]);
    }
    this.companiaPrincipal = JSON.parse(localStorage.getItem('companiaPrincipal'));

    this.thirdCompanies = JSON.parse(localStorage.getItem('companies'));
    this.headerService.getInitData().subscribe((initParams) => {

      this.thirdCompanies = initParams;
      const companies = this.thirdCompanies["companies"];
      const companyKeys = Object.keys(companies);
      let firstCompany = '';

      if (companyKeys.length > 0) {
        const firstKey = companyKeys[0];
        firstCompany = companies[firstKey];
      }

      this.company_prin = firstCompany;
      localStorage.setItem('companies', JSON.stringify(initParams.companies));
      localStorage.setItem('favicon', initParams.favicon);

    });
    // this.getCmsContent();

    // Informacion del proveedor
    this._loginService.getInfoSupplier().subscribe(response => {
      this.mostrarBotonRegistroProveedores = response.agp_registration_supplier;
    });

    this._route.params
      .subscribe(params => {

        const json = {
          data: 20,
        };
        this.getLoginService(json);

      });


  }

  ngOnInit() {

    this.dataForm.inputCompany = Object.keys(this.thirdCompanies['companies'])[0];
    $('[data-toggle="tooltip"]').tooltip();
    // this.logo = localStorage.getItem('logo');
    // this.imagen_login = localStorage.getItem('imagen_login');
    const interval = setInterval(() => {
      this.getContentB2s();
      clearInterval(interval);
    }, 1250);
    // this._loginService.getAdverstismentsLogin().subscribe((data: any) => {
    //   console.log("ENTRE 2");
    //   this.cmsData = data;
    //   console.log(data);
    // });
  }

  authentication(form: NgForm) {
    let userName = form.value.inputEmail;
    let password = form.value.inputPassword;
    const company = Object.keys(this.thirdCompanies['companies'])[0];
    let message = '';

    this.registerForm = this.formBuilder.group({
      email: [userName, [Validators.required, Validators.pattern('^([a-zA-Z0-9_.+-])+\\@(([a-zA-Z0-9-])+\\.)+([a-zA-Z0-9]{2,4})+$')]],
      password: [password, [Validators.required]],
      company: [company, [Validators.required]]
    });

    if (this.registerForm.controls.email.errors) {
      if (this.registerForm.controls.email.errors.required) {
        message = message + '<br>Ingrese el correo electrónico';
      }
      if (this.registerForm.controls.email.errors.pattern) {
        message = message + '<br>Ingrese un correo válido.';
      }
    }
    if (this.registerForm.controls.password.errors) {
      if (this.registerForm.controls.password.errors.required) {
        message = message + '<br>Ingrese la contraseña.';
      }
    }

    if (this.registerForm.invalid) {
      this.toastr.error(message, 'Algunos datos aún no han sido completados.', { enableHtml: true });
      /*swal.fire({
        title: '<p>faltan algunos datos por diligenciar</p>',
        html: message,
        type: 'error',
      });*/
      return;
    }

    this._authService.login({ username: userName, password: password, company: company }).subscribe(authResponse => {

      if (authResponse.data && !authResponse.error) {
        console.log("ENTRE AL LOGIN");
        localStorage.setItem('authenticate', 'true');
        localStorage.setItem('userData', JSON.stringify(authResponse.data));
        localStorage.setItem('selectedCompany', company);
        localStorage.setItem('session_token', authResponse.data.session_token);
        this._router.navigate(['/account-statements', 30]);
        location.reload();
        console.log("ENTRE SALI LOGIN");
      } else {
        localStorage.setItem('authenticate', 'false');
        this.showError('Credenciales Invalidas');
      }
    }, error => {
      if (error.status === 500 || error.status === 0) {
        localStorage.setItem('authenticate', 'false');
        this._router.navigate(['/500']);
      }
    });
  }

  getCmsContent() {
    const ubucation = 'REGISTRO_AGP';
    this._loginService.contentCms(ubucation)
      .subscribe((loginResponse: any) => {
        return this.contentCms = loginResponse;
      });
  }

  getContentB2s() {
    const provider = localStorage.getItem('provider');
    const contact = localStorage.getItem('contact');

    this.contentB2s.contact = parseFloat(contact);
    this.contentB2s.provider = parseFloat(provider);
  }

  showError(message) {
    this.toastr.error(message, '', { enableHtml: true });
  }

  getLoginService(params) {
    this._loginService.getDataLogin(params).pipe(first(), timeout(1000)).subscribe(
      (response: any) => {
        if (response.error) {
          this.logo = localStorage.getItem('logo');
          this.imagen_login = localStorage.getItem('imagen_login');
        } else {
          this.logo = response.logo;
          this.imagen_login = response.imagen_login;

        }
      }, (error: any) => {
        this.logo = localStorage.getItem('logo');
        this.imagen_login = localStorage.getItem('imagen_login');
      }
    );
  }

}

