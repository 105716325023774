import { Component, Renderer2, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { BnNgIdleService } from 'bn-ng-idle';

import { LoginService } from '@app/common/services/login.service';
import { OfflineService } from '@app/common/services/offline.service';
import { EnvService } from '@app/common/services/env.service';
import { AuthService } from '@app/common/services/auth.service';
import { HeaderService } from './common/services/header.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {

  title = 'proveedores';
  flagoffline: boolean;
  contentB2s: any;


  constructor(private renderer: Renderer2,
    private _loginService: LoginService,
    private _router: Router,
    private env: EnvService,
    private _offlineService: OfflineService,
    private bnIdle: BnNgIdleService,
    private _headerService: HeaderService,
    private authService: AuthService,
    @Inject(DOCUMENT) private _document: HTMLDocument) {

    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if ((event.url === '/login') || (event.url === '/register-login') || (event.url === '/password') ||
          (event.url === '/password-recovery') || (event.url.length === 1)) {
          this.renderer.addClass(document.body, 'body-login');
        } else {
          const selectedCompany = localStorage.getItem('selectedCompany');
          this.statusServer(selectedCompany);
          this.renderer.removeClass(document.body, 'body-login');
        }

        if (event.url.indexOf('backend') > -1) {
          window.location.href = `${this.env.apiGatewayBackOffice}`;
        }

        (window as any).ga('set', 'page', event.urlAfterRedirects);
        (window as any).ga('send', 'pageview');
      }
    });


    this._document.getElementById('app-favicon').setAttribute('href', localStorage.getItem('favicon'));
    this._document.getElementById('page_title').innerText = localStorage.getItem('page_title');
  }

  statusServer(selectedCompany) {
    this._headerService.getAGPParams(selectedCompany)
      .subscribe((authResponse: any) => {
        this.flagoffline = authResponse.pageOnline;
        if (authResponse.error || !this.flagoffline) {
          this._router.navigate(['/offline-expectation']);
        }
        this.contentB2s = authResponse;
        localStorage.setItem('logo', this.contentB2s.logo);
        localStorage.setItem('imagen_login', this.contentB2s.imagen_login);
        localStorage.setItem('favicon', this.contentB2s.img_favicon);
        localStorage.setItem('page_title', this.contentB2s.page_title);
        localStorage.setItem('provider', this.contentB2s.provider);
        localStorage.setItem('contact', this.contentB2s.contact);
        localStorage.setItem('date_payment', this.contentB2s.date_payment);
        localStorage.setItem('status_payment', this.contentB2s.status_payment);
        localStorage.setItem('date_invoice', this.contentB2s.date_invoice);
        localStorage.setItem('timeout', this.contentB2s.time_out);
        localStorage.setItem('descarga_certificado', this.contentB2s.descarga_certificado);
        localStorage.setItem('allow_vouchers_download', this.contentB2s.allow_vouchers_download);

        this.setTimeoutSession(this.contentB2s.time_out * 60);

        this._document.getElementById('app-favicon').setAttribute('href', localStorage.getItem('favicon'));
        this._document.getElementById('page_title').innerText = localStorage.getItem('page_title');
      }, error => {
        if (error.status === 500 || error.status === 0) {
          this._router.navigate(['/500']);
        }
      });
  }

  setTimeoutSession(timeoutSeconds) {
    this.bnIdle.startWatching(timeoutSeconds).subscribe((res) => {
      if (res) {
        this.authService.logoutService();
      }
    });
  }
}
